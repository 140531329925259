/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

@use '@simpl/marengo-mobile-theme/marengo-styles' as *;

$font-path: '~@simpl/marengo-icons/dist/fonts/';
@use '@simpl/marengo-icons/dist/style/simpl-marengo-icons' as *;

/* Core CSS required for Ionic components to work properly */
@use "@ionic/angular/css/core.css" as *;

/* Basic CSS for apps built with Ionic */
@use "@ionic/angular/css/normalize.css" as *;
@use "@ionic/angular/css/structure.css" as *;
@use "@ionic/angular/css/typography.css" as *;
@use '@ionic/angular/css/display.css' as *;

/* Optional CSS utils that can be commented out */
@use "@ionic/angular/css/padding.css" as *;
@use "@ionic/angular/css/float-elements.css" as *;
@use "@ionic/angular/css/text-alignment.css" as *;
@use "@ionic/angular/css/text-transformation.css" as *;
@use "@ionic/angular/css/flex-utils.css" as *;
@use "../src/theme/variables.scss" as *;

ion-app {
    font-family: $font-family-base;
}
body {
    margin-top: constant(safe-area-inset-top);
    margin-top: env(safe-area-inset-top);
}

ion-tab-bar {
    margin-bottom: calc(4px + env(safe-area-inset-top));
}

ion-tabs{
    background-color: white;
}

.button-native {
    border-color: #007993;
}

.ios{
    --ion-safe-area-top : 0 !important;
}

.ios-bottom-safe-area {
    padding-bottom: var(--ion-safe-area-bottom) !important;
    padding-bottom: env(safe-area-inset-bottom) !important;
    background-color: var(--ion-color-secondary);  
}