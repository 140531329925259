// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/
$font-family-base: 'SiemensSans';
@font-face {
    font-family: 'SiemensSans';
    src: url('../assets/fonts/siemensSans/Bold.woff')  format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  //
  @font-face {
    font-family: 'SiemensSans';
    src: url('../assets/fonts/siemensSans/BoldItalic.woff')  format('truetype');
    font-weight: bold;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'SiemensSans';
    src: url('../assets/fonts/siemensSans/Italic.woff')  format('truetype');
    font-weight: normal;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'SiemensSans';
    src: url('../assets/fonts/siemensSans/Roman.woff')  format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  